<template>
  <div>
    <v-row v-if="addWish === 0 && editing === 0">
      <v-spacer></v-spacer>
      <v-btn @click="addWish = 1" color="primary" right class="mb-3">
        <v-icon left>mdi-plus-box</v-icon>
        Add wish
      </v-btn>
    </v-row>
    <v-row v-if="addWish === 0">
      <v-col v-for="item in wishlists" :key="item.id" cols="12" :md="editing === 0 ? 6 : 12">
        <v-card class="pa-3">
          <div v-if="editingWishList !== item.id" class="d-flex justify-space-between">
            <span class="text-bold">City:</span>{{ item.name }}
          </div>
          <div v-else>
            <label>City name</label>
            <v-text-field
              outlined
              v-model="editedWishName"
              label="City"
              :rules="[rules.required]"
            >
            </v-text-field>
          </div>
          <v-divider v-if="editingWishList !== item.id" class="my-2"></v-divider>
          <div v-if="editingWishList !== item.id" class="d-flex justify-space-between"><span
            class="text-bold">Location name/Event:</span>{{ item.location }}
          </div>
          <div v-else>
            <label>Location/Event name</label>
            <v-text-field
              v-model="editedWishLocation"
              outlined
              label="Location/Event"
              :rules="[rules.required]"
            >
            </v-text-field>
          </div>
          <v-divider v-if="editingWishList !== item.id" class="my-2"></v-divider>
          <div v-if="editingWishList !== item.id" class="d-flex justify-space-between overflow-hidden">
            <span class="text-bold">Comment:</span>{{ item.comment }}
          </div>
          <div v-else>
            <label>Comment</label>
            <v-textarea
              v-model="editedWishComment"
              outlined
              label="Comment"
              :rules="[rules.required]"
            >
            </v-textarea>
          </div>
          <v-divider v-if="editingWishList !== item.id" class="my-2"></v-divider>
          <div v-if="editingWishList !== item.id" class="d-flex justify-space-between"><span
            class="text-bold">Preferred date:</span>{{ item.preferred_date }}
          </div>
          <div v-else>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="10"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedWishPreferredDate"
                  label="Preferred date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  color="blue"
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="my-4"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editedWishPreferredDate"
                @input="startDateMenu = false"
                :min="new Date().toISOString().substr(0, 10)"
                show-current
              ></v-date-picker>
            </v-menu>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
          <div class="wishlist-actions">
          <v-btn width="15" v-if="editingWishList === 0" @click="editingWishList = item.id; editing = 1; useWishList(item.id)" color="primary"
                 class="mt-7 ml-auto" text>
            <v-icon>mdi-pencil-outline</v-icon>Edit
          </v-btn>
          <v-btn width="25" text :disabled ="wishDeleteBtnLoad" :loading="wishDeleteBtnLoad" @click="deleteWishList(item.id)" v-if="editingWishList !== item.id" color="primary" class="mt-7 ml-3">
            <v-icon>mdi-delete</v-icon>Delete
          </v-btn>
          </div>
          </v-card-actions>
          <div class="d-flex justify-center">
          <v-btn :loading="wishEditBtnLoad" v-if="editingWishList === item.id" @click="updatewishlist(item.id)" color="primary" class="mt-2 ml-2">
            Submit
          </v-btn>
          <v-btn v-if="editingWishList === item.id" @click="editingWishList = 0; editing = 0" color="primary" text class="mt-2 ml-2">
            Cancel
          </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="pa-4" v-else>
      <v-card-title class="primary--text">Add to wishlist</v-card-title>
      <v-form ref="wishlistform" v-model="valid">
            <label>Location/Event name</label>
          <v-text-field v-model="wishLocation"
                        outlined
                        placeholder="Location/Event name"
                        :rules="[rules.required]"
          class="mt-1">
          </v-text-field>
       <v-row>
         <v-col cols="12" md="6">
           <label>City</label>
        <v-text-field v-model="wishName"
                      outlined
                      placeholder="City name"
                      :rules="[rules.required]">
        </v-text-field>
         </v-col>
         <v-col cols="12" md="6">
           <label>Preferred Date</label>
         <v-menu
           v-model="menu"
           :close-on-content-click="false"
           :nudge-right="10"
           transition="scale-transition"
           min-width="auto"
         >
           <template v-slot:activator="{ on, attrs }">
             <v-text-field
               v-model="wishPreferredDate"
               label="Preferred date"
               append-icon="mdi-calendar"
               readonly
               color="blue"
               :rules="[rules.required]"
               v-bind="attrs"
               v-on="on"
               outlined
               clearable
             ></v-text-field>
           </template>
           <v-date-picker
             v-model="wishPreferredDate"
             @input="startDateMenu = false"
             :min="new Date().toISOString().substr(0, 10)"
             show-current
           ></v-date-picker>
         </v-menu>
         </v-col>
       </v-row>
        <label>Comment</label>
        <v-textarea v-model="wishComment"
                      outlined
                      placeholder="Comment"
                      :rules="[rules.required]">
        </v-textarea>
        <div class="d-flex justify-center">
        <v-btn :loading="wishBtnLoad" @click="addwishlist" color="primary" right class="mb-3">
          Submit
        </v-btn>
        <v-btn @click="addWish = 0" color="primary" right class="mb-3" text>
          Cancel
        </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import user from '@/web_services/user'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      wishlists: null,
      wishBtnLoad: false,
      wishEditBtnLoad: false,
      wishDeleteBtnLoad: false,
      editingWishList: 0,
      addWish: 0,
      editing: 0,
      valid: true,
      wishName: null,
      editedWishName: null,
      editedWishLocation: null,
      wishComment: null,
      wishLocation: null,
      editedWishComment: null,
      wishPreferredDate: null,
      editedWishPreferredDate: null,
      menu: null,
      menu1: null,
      rules: {
        required: value => !!value || 'This field Required.'
      }
    }
  },
  methods: {
    useWishList (id) {
      const currentWishlist = this.wishlists.find(x => x.id === id)
      this.editedWishName = currentWishlist.name
      this.editedWishComment = currentWishlist.comment
      this.editedWishLocation = currentWishlist.location
      this.editedWishPreferredDate = currentWishlist.preferred_date
    },
    addwishlist () {
      if (this.$refs.wishlistform.validate()) {
        if (this.valid) {
          this.wishBtnLoad = true
          const wishlist = {
            name: this.wishName,
            user_id: this.user.id,
            city_id: 1,
            country_id: 1,
            comment: this.wishComment,
            location: this.wishLocation,
            preferred_date: this.wishPreferredDate
          }
          user.addwishlist(wishlist).then(res => {
            this.wishlists = res.data.data
          }).catch(error => {
            this.$emit('error', error)
          }).finally(() => {
            this.addWish = 0
            this.$emit('success', 'Wishlist Item added')
            this.wishBtnLoad = false
            this.wishComment = ''
            this.wishName = ''
            this.wishLocation = ''
            this.wishPreferredDate = ''
          })
        }
      }
    },
    updatewishlist (id) {
      const wishlist = this.wishlists.find(x => x.id === id)
      const wishlistEdited = {}
      if (this.editedWishName !== wishlist.name && this.editedWishName !== '') {
        wishlistEdited.name = this.editedWishName
      }
      if (this.editedWishComment !== wishlist.comment && this.editedWishComment !== '') {
        wishlistEdited.comment = this.editedWishComment
      }
      if (this.editedWishLocation !== wishlist.location && this.editedWishLocation !== '') {
        wishlistEdited.location = this.editedWishLocation
      }
      if (this.editedWishPreferredDate !== wishlist.preferred_date && this.editedWishPreferredDate !== '') {
        wishlistEdited.preferred_date = this.editedWishPreferredDate
      }
      if (Object.keys(wishlistEdited).length !== 0) {
        this.wishEditBtnLoad = true
        user.updatewishlist(wishlistEdited, id).then(res => {
          this.wishlists = res.data.data
        }).catch(error => {
          this.$emit('error', error)
        }).finally(() => {
          this.editingWishList = 0
          this.editing = 0
          this.$emit('success', 'Wishlist item edited')
          this.wishEditBtnLoad = false
        })
      } else {
        this.editingWishList = 0
        this.editing = 0
      }
    },
    deleteWishList (id) {
      this.wishDeleteBtnLoad = true
      user.deleteWishList(id).then(() => {
        user.wishlist().then(res => {
          this.wishlists = res.data.data
        }).catch(error => {
          this.$emit('error', error)
        })
      }).catch(error => {
        this.$emit('error', error)
      }).finally(() => {
        this.wishDeleteBtnLoad = false
        this.$emit('success', 'Wishlist Deleted')
      })
    }
  },
  created () {
    user.wishlist().then(res => {
      if (res.status === 200) {
        this.wishlists = res.data.data
      }
    })
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
<style scoped>
</style>
